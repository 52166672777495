import React, { Component } from 'react';

import domtoimage from 'dom-to-image';
import {geneString, saveNarco, skins, fixDigit , femaleMaxVals} from "./CreatorUtil"
import {updateNarco, recruitNarco} from "./Contracts"

class FemaleCreator extends Component {

  constructor(props) {
     super(props)

     var currentGenes = [1,6,1,1,48,30,20,37,36,50,40,44,1,12,1,27,1,50];
     if (this.props.operation=="change" && this.props.currentGenes!=undefined && this.props.currentGender=="f"){
       currentGenes = this.props.currentGenes;
     }

     this.state = {
     narcoId: this.props.narcoId,
     genes: currentGenes,
     maxvals: femaleMaxVals,
     savingNarco: false,
     saved: false
     }
   }
   handleChange (e, layer) {
     console.log('handle change called')
     console.log(e.target.value);
     console.log(layer);

     var genes = this.state.genes;
     genes[layer]=e.target.value;
     this.setState({
       genes: genes,
       savingNarco: false,
       saved: false

     })

   }

   randomize (e) {

     var genes = this.state.genes;
     for (var i=0;i<genes.length;i++){
       genes[i]=Math.floor(Math.random() * this.state.maxvals[i]) + 1;
       console.log( genes[i]);
     }
     this.setState({
       genes: genes,
       savingNarco: false,
       saved: false
     })
   }

   doneUpdating(){
      window.location.href = "https://play.doperaider.com";
   }

   startUpdating(){
     this.setState({savingNarco: true, saved:false})
   }


   nameError(error){
       this.setState({nameError: true, nameIssue: error})
   }

   nameOk(){
       this.setState({nameError: false})
   }

      saveMyNarco(){
        saveNarco(this);
        this.setState({savingNarco: true})
      }

      doneSaving(){
         this.setState({savingNarco: false, saved: true})
      }


  render() {
    return (

        <div class="container">
        <div class="row">

        <div class="col-sm-3">

        <button type="button" class="btn btn-primary col-12 mt-5" onClick={(e) => {this.randomize(e)}}>Randomize</button>

        <div class="slidecontainer">
            <label>&nbsp;</label>
        </div>


        <div class="slidecontainer">
                <label>Skin</label>&nbsp;
                <input type="range" min="1" max={this.state.maxvals[0]} class="slider" value={this.state.genes[0]}  onChange={(e) => {this.handleChange(e, 0)}} />
            </div>
            <div class="slidecontainer">
                <label>Tattoo</label>&nbsp;
                <input type="range" min="1" max={this.state.maxvals[1]} class="slider"  value={this.state.genes[1]}  onChange={(e) => {this.handleChange(e, 1)}} />
            </div>
            <div class="slidecontainer">
                <label>Eyebrows</label>&nbsp;
                <input type="range" min="1" max={this.state.maxvals[2]} class="slider"  value={this.state.genes[2]}  onChange={(e) => {this.handleChange(e, 2)}} />
            </div>
            <div class="slidecontainer">
                <label>Nose</label>&nbsp;
                <input type="range" min="1" max={this.state.maxvals[3]} class="slider"  value={this.state.genes[3]}  onChange={(e) => {this.handleChange(e, 3)}} />
            </div>
            <div class="slidecontainer">
                <label>Eyes</label>&nbsp;
                <input type="range" min="1" max={this.state.maxvals[4]} class="slider"  value={this.state.genes[4]}  onChange={(e) => {this.handleChange(e, 4)}} />
            </div>
            <div class="slidecontainer">
                <label>Glasses</label>&nbsp;
                <input type="range" min="1" max={this.state.maxvals[5]} class="slider"  value={this.state.genes[5]}  onChange={(e) => {this.handleChange(e, 5)}} />
            </div>
            <div class="slidecontainer">
                <label>Accessorize</label>&nbsp;
                <input type="range" min="1" max={this.state.maxvals[6]} class="slider"  value={this.state.genes[6]}  onChange={(e) => {this.handleChange(e, 6)}} />
            </div>
            <div class="slidecontainer">
                <label>Socks</label>&nbsp;
                <input type="range" min="1" max={this.state.maxvals[7]} class="slider"  value={this.state.genes[7]}  onChange={(e) => {this.handleChange(e, 7)}} />
            </div>
            <div class="slidecontainer">
                <label>Undies</label>&nbsp;
                <input type="range" min="1" max={this.state.maxvals[8]} class="slider"  value={this.state.genes[8]}  onChange={(e) => {this.handleChange(e, 8)}} />
            </div>


        </div>

            <div class="col-sm-6">
            <div class="narcoContainer">
              <div class="narcoAvatar" id="narcoAvatar">
                <div><img width="300" class="narcos" src={"avatars/f/f-shadow.png"}/></div>
                <div><img width="300" class="narcos" src={"avatars/f/t-skin/"+skins[this.state.genes[0]]+".png"}/></div>
                <div><img width="300" class="narcos" src={"avatars/f/r-tattoo/"+fixDigit(this.state.genes[1])+".png"}/></div>
                <div><img width="300" class="narcos" src={"avatars/f/q-eyebrows/"+this.state.genes[2]+"/"+skins[this.state.genes[0]]+".png"}/></div>
                <div><img width="300" class="narcos" src={"avatars/f/p-nose/"+this.state.genes[3]+"/"+skins[this.state.genes[0]]+".png"}/></div>
                <div><img width="300" class="narcos" src={"avatars/f/o-eyes/"+fixDigit(this.state.genes[4])+".png"}/></div>
                <div><img width="300" class="narcos" src={"avatars/f/n-glasses/"+fixDigit(this.state.genes[5])+".png"}/></div>
                <div><img width="300" class="narcos" src={"avatars/f/m-accessorie/"+fixDigit(this.state.genes[6])+".png"}/></div>
                <div><img width="300" class="narcos" src={"avatars/f/l-socks/"+fixDigit(this.state.genes[7])+".png"}/></div>
                <div><img width="300" class="narcos" src={"avatars/f/k-undies/"+fixDigit(this.state.genes[8])+".png"}/></div>
                <div><img width="300" class="narcos" src={"avatars/f/j-shoes/"+fixDigit(this.state.genes[9])+".png"}/></div>
                <div><img width="300" class="narcos" src={"avatars/f/i-shirt/"+fixDigit(this.state.genes[10])+".png"}/></div>
                <div><img width="300" class="narcos" src={"avatars/f/h-pants/"+fixDigit(this.state.genes[11])+".png"}/></div>
                <div><img width="300" class="narcos" src={"avatars/f/e-chin/"+this.state.genes[12]+"/"+skins[this.state.genes[0]]+".png"}/></div>
                <div><img width="300" class="narcos" src={"avatars/f/d-beard/"+fixDigit(this.state.genes[13])+".png"}/></div>
                <div><img width="300" class="narcos" src={"avatars/f/c-mouth/"+this.state.genes[14]+"/"+skins[this.state.genes[0]]+".png"}/></div>
                <div><img width="300" class="narcos" src={"avatars/f/b-hair/"+fixDigit(this.state.genes[15])+".png"}/></div>
                <div><img width="300" class="narcos" src={"avatars/f/a-hand/"+this.state.genes[16]+"/"+skins[this.state.genes[0]]+".png"}/></div>
                <div><img width="300" class="narcos" src={"avatars/f/a1-others/"+fixDigit(this.state.genes[17])+".png"}/></div>
              </div>
            </div>


            <div class="row">
            &nbsp;
            </div>


            <div class={(this.state.saved==true)? "genesInfo row": " genesInfoHidden "} id="genesInfo">

            {(this.props.operation=="change")?
                <div class={(this.state.saved==true)? "genesInfo row": " genesInfoHidden "}>
                <input type="hidden" id="narcoGenes" value={geneString("f",this.state.genes)} size="36"/>
                Name: &nbsp;&nbsp;
                {
                  (this.state.nameError==true)?
                    <span class="text-danger"> {this.state.nameIssue}</span>
                  :
                  null
                }
                <input type="text" id="newName" size="19" defaultValue={this.props.narcoName} /> <span class="btn btn-primary" onClick={(e) => {updateNarco(this.state.narcoId, geneString("f",this.state.genes) , document.getElementById('newName').value , this)}}>Change Identity Now</span>
                Cost: 150 POA
                </div>
                :
                null
            }

            {(this.props.operation=="recruit")?
                <div class={(this.state.saved==true)? "genesInfo row": " genesInfoHidden "}>
                <input type="hidden" id="narcoGenes" value={geneString("f",this.state.genes)} size="36" />
                Name: &nbsp;&nbsp;
                {
                  (this.state.nameError==true)?
                    <span class="text-danger"> {this.state.nameIssue}</span>
                  :
                  null
                }
                <input type="text" id="newName" size="19" defaultValue={"#noob" + Math.floor(Math.random() * 5000) + 1  } /> <span class="btn btn-primary" onClick={(e) => {recruitNarco(this.state.narcoId, geneString("f",this.state.genes) , document.getElementById('newName').value , this)}}>Recruit This Narco</span>
                </div>
                :
                null
            }


            {(this.props.operation!="change")?
              <div class={(this.state.saved==true && (this.props.operation=="create" || this.props.operation==undefined))? "genesInfo row": " genesInfoHidden "}>
                  <label>Genes</label>&nbsp;<br/>
                  <input type="text" id="narcoGenes" value={geneString("f",this.state.genes)} size="36"/>
                  <p/>
                  <label>Link</label>&nbsp;<br/>
                  <input type="text" id="narcoLink" value={"https://narcos.doperaider.com/narcos/" + geneString("f",this.state.genes)+ ".png" } size="36"/>
              </div>
                :
                null
            }

            </div>



            </div>
            <div class="col-sm-3">

            {(this.state.savingNarco==false) ?
              <div class="btn btn-primary col-12 mt-5" onClick={(e) => {this.saveMyNarco()}}>Oh Yeah! Store it.</div>
              :
              <div class="btn btn-primary col-12 mt-5"><div><img src="css/ripple.gif" height="25px;"/></div></div>

            }

            <div class="slidecontainer">
                <label>&nbsp;</label>
            </div>


            <div class="slidecontainer">
                <label>Shoes</label>&nbsp;
                <input type="range" min="1" max={this.state.maxvals[9]} class="slider"  value={this.state.genes[9]}  onChange={(e) => {this.handleChange(e, 9)}} />
            </div>
            <div class="slidecontainer">
                <label>Top</label>&nbsp;
                <input type="range" min="1" max={this.state.maxvals[10]} class="slider"  value={this.state.genes[10]}  onChange={(e) => {this.handleChange(e, 10)}} />
            </div>
            <div class="slidecontainer">
                <label>Bottom</label>&nbsp;
                <input type="range" min="1" max={this.state.maxvals[11]} class="slider"  value={this.state.genes[11]}  onChange={(e) => {this.handleChange(e, 11)}} />
            </div>

            <div class="slidecontainer">
                  <label>Chin</label>&nbsp;
                  <input type="range" min="1" max={this.state.maxvals[12]} class="slider"  value={this.state.genes[12]}  onChange={(e) => {this.handleChange(e, 12)}} />
            </div>
            <div class="slidecontainer">
                  <label>Beard</label>&nbsp;
                  <input type="range" min="1" max={this.state.maxvals[13]} class="slider"  value={this.state.genes[13]}  onChange={(e) => {this.handleChange(e, 13)}} />
            </div>
            <div class="slidecontainer">
                  <label>Mouth</label>&nbsp;
                  <input type="range" min="1" max={this.state.maxvals[14]} class="slider"  value={this.state.genes[14]}  onChange={(e) => {this.handleChange(e, 14)}} />
            </div>
            <div class="slidecontainer">
                  <label>Hair</label>&nbsp;
                  <input type="range" min="1" max={this.state.maxvals[15]} class="slider"  value={this.state.genes[15]}  onChange={(e) => {this.handleChange(e, 15)}} />
            </div>
            <div class="slidecontainer">
                  <label>Hand</label>&nbsp;
                  <input type="range" min="1" max={this.state.maxvals[16]} class="slider"  value={this.state.genes[16]}  onChange={(e) => {this.handleChange(e, 16)}} />
            </div>
            <div class="slidecontainer">
                  <label>Icing</label>&nbsp;
                  <input type="range" min="1" max={this.state.maxvals[17]} class="slider"  value={this.state.genes[17]}  onChange={(e) => {this.handleChange(e, 17)}} />
                  </div>


                  </div>
                  </div>


              </div>
    );
  }
}

export default FemaleCreator;
