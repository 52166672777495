import React, { Component } from 'react';
import ReactQueryParams from 'react-query-params';
import {narcoCoreContractInstance, getWeb3 , usedNarcoName , narcoContractABI ,poalNarcoContractAddress } from "./Contracts"

import MaleCreator from "./MaleCreator"
import FemaleCreator from "./FemaleCreator"

import {
  FacebookShareButton,
  GooglePlusShareButton,
  TwitterShareButton,
  TelegramShareButton,
  PinterestShareButton,
  RedditShareButton,
  EmailShareButton,

  FacebookIcon,
  GooglePlusIcon,
  TwitterIcon,
  TelegramSIcon,
  PinterestIcon,
  RedditIcon,
  EmailIcon

} from 'react-share';
import {isMobile , isFirefox , isChrome} from 'react-device-detect';

import {isAllowedAddress} from './AllowedCreators'
import Web3 from 'web3';
const web3I = new Web3(Web3.givenProvider);
const narcoCoreContract = new web3I.eth.Contract(narcoContractABI, poalNarcoContractAddress);


class App extends ReactQueryParams {

  constructor(props) {
     super(props);
     this.state = {
        initialized: false,
        creator: "m",
        accts: []
     }
   }

   componentWillMount=async()=>{

    let accts=  [];
    if (Web3.givenProvider !== null) {

    //console.log(web3I);
     await web3I.eth.requestAccounts();
     accts = await web3I.eth.getAccounts();
   }

    this.setState({accts:accts})

     var narcoId = this.queryParams.id;
     var operation = "create";

     if (this.queryParams.op=="c"){
       operation="change";
     }
     if (this.queryParams.op=="r"){
       operation="recruit";
     }

     var gender = "m";
     this.setState ({
        initialized: false,
        creator: gender,
        operation: operation
     })


     if (narcoId!=undefined && parseInt(narcoId)>0){

          const ownerResult = await  narcoCoreContract.methods.ownerOf(narcoId).call();
          console.log(ownerResult);

          if(ownerResult == accts[0]){

          let result =  await  narcoCoreContract.methods.getNarco(narcoId).call();

          if(result != null){

            console.log(result);

            var gender= "x";
            if (result[5].startsWith("f")){
              gender = "f"
            }
            if (result[5].startsWith("m")){
              gender = "m"
            }
            var genes = [];
            if (result[5].startsWith("f") || result[5].startsWith("m")){

              // calculate the current genes
              for (var g = 1;g<result[5].length-1;g=g+2){
                var gene = parseInt(result[5].substring(g,g+2));
                genes.push(gene);
              }
            }

            console.log(genes);

              this.setState ( {
                 narcoId : narcoId,
                 narcoName: result[0],
                 currentGenes: genes,
                 initialized: true,
                 creator: gender,
                 currentGender: gender
              })

          } // end result

      }

    }


   }



  render() {

    if (isMobile) {
           return (
             <div>

               <div class="row">
                   <div class="creatorbuttons mx-auto m-3 d-flex justify-content-center">
                   <div class="bangers text-center">
                         <p>dopraider narco designer</p>
                         <p>Coming to mobile soon</p>
                         <a href="https://doperaider.com">Take me back!</a>
                   </div>

                   </div>
               </div>

               <div>

               </div>

               </div>

           )
    }

    if (!isChrome && !isFirefox) {
           return (
             <div>

               <div class="row">
                   <div class="creatorbuttons mx-auto m-3 d-flex justify-content-center">
                   <div class="bangers text-center">
                         <p>dopraider narco designer</p>
                         <p>Supported on Chrome and Firefox only</p>
                         <a href="https://doperaider.com">Take me back!</a>
                   </div>

                   </div>
               </div>

               <div>

               </div>

               </div>

           )
    }
    var web3 = getWeb3();

    /*
    if (this.state.operation=="create" && !isAllowedAddress(this.state.accts[0])) {
           return (
             <div>

               <div class="row">
                   <div class="creatorbuttons mx-auto m-3 d-flex justify-content-center">
                   <div class="bangers text-center">
                         <p>dopraider narco designer</p>
                         <p>You are not allowed to do this right now.</p>
                         <a href="https://doperaider.com">Take me back!</a>
                   </div>

                   </div>
               </div>

               <div>

               </div>

               </div>

           )
    }*/




    /** take some time to get initialized */
    if (this.state.initialized == false) return (
      <div class="row h-100">
      <div class="col-lg-5">&nbsp;</div>
      <div class="col-lg-2 my-auto"><img src="images/general-loader.gif"/></div>
      <div class="col-lg-5">&nbsp;</div>
      </div>
    )

    if (this.state.operation=="narcoPreview"){
      var genes = this.queryParams.genes;

      var title = "I designed this Narco for DopeRaider";
      var shareUrl = "https://narcos.doperaider.com/?operation=narcoPreview&genes="+genes;

      return (
          <div>
                <div class="row">

                <div class="col-sm-4 text-right">

                </div>
                  <div class="col-sm-4">
                    <a class="mainlink" href="https://doperaider.com"><img src="./css/logo-down.png" width="50%"/></a>
                    <div class="text-center">Produce, trade and raid dope on the blockchain! </div>
                  </div>
                  <div class="col-sm-4">
                  </div>

                </div>
                <div class="row">
                <div class="col-sm-4">
                </div>
                  <div class="col-sm-4 text-center">
                      <img src={"https://narcos.doperaider.com/narcos/"+genes+".png"}/>
                  </div>
                  <div class="col-sm-4">
                  </div>
                </div>

                <div class="row creatorbuttons">
                <div class="col-sm-4">
                </div>
                    <div class="col-sm-1 text-center">

                    <FacebookShareButton
                          url={shareUrl}
                          quote={title}
                          >
                          <FacebookIcon
                            size={32}
                            square />
                      </FacebookShareButton>



                    </div>
                    <div class="col-sm-1">
                    <TwitterShareButton
                          url={shareUrl}
                          title={title}
                          hashtags={["ethereum", "eth" , "cryptocurrency" , "cryptogames" , "doperaider" , "blockchain"]}
                          >
                          <TwitterIcon
                            size={32}
                            square />
                      </TwitterShareButton>

                    </div>


                    <div class="col-sm-1">
                    <RedditShareButton
                              url={shareUrl}
                              title={title}
                              windowWidth={660}
                              windowHeight={460}
                              className="Demo__some-network__share-button">
                              <RedditIcon
                                size={32}
                                round />
                            </RedditShareButton>

                    </div>


                    <div class="col-sm-1">
                    <PinterestShareButton
           url={shareUrl}
           media={"https://narcos.doperaider.com/narcos/"+genes+".png"}
           windowWidth={1000}
           windowHeight={730}
           >
           <PinterestIcon size={32} round />
         </PinterestShareButton>


                    </div>

                </div>
          </div>
        );


    }

    var title = "create narco";

    if (this.state.operation=="recruit"){
      title = this.state.narcoName + " is recruiting!!";
    }

    if (this.state.operation=="change"){
      title = this.state.narcoName + " is changing identity";
    }

    return (

        <div>

          <div class="row">
              <div class="creatorbuttons mx-auto m-3 d-flex justify-content-center">
              <div class="bangers text-center">
                    {title}
              </div>
                <div><a href="#" onClick={(e) => {this.setState({creator: 'm'})}} >Male</a></div>
                <div><a href="#" onClick={(e) => {this.setState({creator: 'f'})}} >Female</a></div>
                <div><a href="https://play.doperaider.com">Back</a></div>
              </div>
          </div>

          <div>

          {(this.state.creator=="m") ? <MaleCreator currentGender={this.state.currentGender} operation={this.state.operation} narcoId={this.state.narcoId} narcoName={this.state.narcoName} currentGenes={this.state.currentGenes}/> : <FemaleCreator currentGender={this.state.currentGender} operation={this.state.operation} narcoName={this.state.narcoName} narcoId={this.state.narcoId} currentGenes={this.state.currentGenes}/>}

          </div>

          </div>


    );
  }
}

export default App;
