import domtoimage from 'dom-to-image';


export const skins = ["" , "afroA" , "afroB" , "asian" , "euro" , "indian", "latin" , "robot" , "vamp" ];

export const maleMaxVals = [8,10,10,10,10,43,25,35,15,12,20,30,23,40,0,10,21,14,32,20];
export const femaleMaxVals = [8,6,8,8,48,30,20,37,36,50,40,44,8,12,9,27,14,50];

export function fixDigit(g){

  var gene = ""+g;
  if (gene.length<2){
    gene="0"+gene;
  }
  return gene;
}

var albumBucketName = 'narco-images.doperaider.com';
var bucketRegion = 'eu-west-1';
var IdentityPoolId = 'eu-west-1:ad9a9c17-1503-4422-9a00-acb43f19f4cd';

// import entire SDK
var AWS = require('aws-sdk');

AWS.config.update({
  region: bucketRegion,
  credentials: new AWS.CognitoIdentityCredentials({
    IdentityPoolId: IdentityPoolId
  })
});

var s3 = new AWS.S3({
  apiVersion: '2006-03-01',
  params: {Bucket: albumBucketName}
});


export function saveNarco(creator) {

//  document.getElementById('saveNarcoButton').style.display = 'none'

  var genesString = document.getElementById('narcoGenes').value;
  domtoimage.toBlob(document.getElementById('narcoAvatar'))
      .then(function (blob) {

        var file = blob;
        var fileName = genesString+".png";
        //var albumPhotosKey = encodeURIComponent(albumName) + '//';

        var narcoKey = "narcos/" + fileName;
        s3.upload({
          Key: narcoKey,
          Body: file,
          ContentType: "image/png",
          ACL: 'public-read'
        }, function(err, data) {
          // document.getElementById('saveNarcoButton').style.display = 'block'

          if (err) {
            return alert('There was an error uploading your narco: ', err.message);
          }

          creator.doneSaving();

        });

      });
}

export function geneString(creatorType, genes){
  var geneString = creatorType;
  for (var i=0;i<genes.length;i++){

    var gene = ""+genes[i];
    if (gene.length<2){
      gene="0"+gene;
    }
    geneString+=gene;
  }
  return geneString;
}
